import React from 'react';

import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';

import Field from '../components/form/Field';
import PasswordField from '../components/form/PasswordField';
import SubmitButton from '../components/form/SubmitButton';
import CognitoError from '../components/form/CognitoError';

export default function NewPassword(props) {
  const { 
    user,
    setUser,
    password,
    passwordError,
    setPasswordError,
    handlePasswordChange,
    setDocumentTitle,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);
  const [cognitoError, setCognitoError] = React.useState();
  const [fullName, setFullName] = React.useState('');
  const handleFullNameChange = (event) => setFullName(event.target.value);

  const nameRequired = user?.challengeParam?.requiredAttributes?.includes('name') ?? false;

  React.useEffect(() => setDocumentTitle('3S | Reset Password'))

  const handleSubmit = async () => {
    // Validation.
    if (!password) {
      setCognitoError('Password Required');
      return;
    }
    if (passwordError) {
      setCognitoError('Check Errors');
      return;
    }

    try {
      setSubmitting(true);
      const requiredAttributes = {};
      if (nameRequired) {
        requiredAttributes.name = fullName;
      }
      const newUser = await Auth.completeNewPassword(user, password, requiredAttributes);
      setUser(newUser);
    }
    catch (error) {
      const { message } = error;
      setCognitoError(message);
    }
    setSubmitting(false);
  }

  return (
    <form onSubmit={ (e) => {handleSubmit(); e.preventDefault();} }>
      { nameRequired && <Field value={fullName} autoComplete="name" label="Full Name" required={true} autoFocus={true} onChange={handleFullNameChange} /> }
      <Typography>
        Please enter your new password.
      </Typography>
      <br/>
      <PasswordField value={password} error={passwordError} onChange={handlePasswordChange} autoFocus={true} />
      { cognitoError && <CognitoError error={cognitoError} /> }
      <SubmitButton disabled={submitting} label="Set Password" />
    </form>
  )
}
